<template>
  <div class="pic-list-block">
    <div class="title">
      <div class="left light-blue h1">{{title}}</div>
      <div class="middle">
        <div :class="{'item': true, 'light-blue': current == i}" v-for="(item, i) in topLinks" :key="i" @click="switchClass(item,i)">
          {{item.name}}
        </div>
      </div>
      <div class="more" @click="handleMoreClick">更多>></div>
    </div>
    <div class="list">
      <div class="item bg-rgba-w-1" v-for="(item, i) in dataList[topLinks[current].code]" :key="i">
        <img :src="item.pictureUrl" class="img"/>
        <div class="link" v-if="type != 3" @click="navTo(item)">快速预约</div>
        <div :class="{'label':true, 'height2': type == 3}">{{item.deviceName || item.instrumentName}}</div>
        <div class="price" v-if="type == 3" @click="navTo(item)">¥ {{item.price}}</div>
        <div class="label" v-else>{{item.belongUnit}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'picList',
  props: {
    type: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: '大科学装置共享'
    },
    topLinks: {
      type: Array,
      default: () => []
    },
    path: {
      type: String,
      default: ''
    },
    dataList: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      current: 0
    }
  },
  computed:{
  },
  mounted() {
    
  },
  methods: {
    handleMoreClick() {
      // this.$router.push({path: this.path, query: {code: this.topLinks[this.current].code}})
      this.$router.push({path: this.path})
    },
    navTo(item) {
      this.$router.push({
        path: item.path || '/sharedInstrument/instrumentDetail', 
        query: {
          id: item.id, 
          title: this.title,
          type: this.type
        }
      })
    },
    switchClass(item, i) {
      this.current = i
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.pic-list-block {
  margin-top: 40px;
  .title {
    display: flex;
    position: relative;
    border-bottom: 2px solid rgba(255, 255, 255, .1);
    padding-bottom: 10px;
    .more {
      position: absolute;
      bottom: 10px;
      right: 20px;
      cursor: pointer;
    }
    .middle {
      display: flex;
      padding: 2px 0 0 30px;
      .item {
        position: relative;
        padding-right: 30px;
        font-size: 18px;
		    cursor: pointer;
        &:last-child::after{
          border: none;
        }
      }
      .item::after {
        position: absolute;
        content: "";
        border-left: 2px solid #fff;
        transform: rotate(30deg);
        height: 18px;
        top: 4px;
        right: 13px;
      }
    }
  }
  .list {
    display: flex;
    margin-top: 20px;
    min-height: 300px;
    .item {
      width: 18%;
      margin: 0 1.25%;
      padding: 10px;
      box-sizing: border-box;
      min-height: 200px;
      position: relative;
      .img {
        width: 100%;
        height: 228px;
        object-fit: cover;
        display: block;
      }
      .label {
        padding: 5px 0;
      }
      .price {
        font-size: 34px;
        color: #F1AF53;
        padding: 5px 0;
        cursor: pointer;
      }
      .height2 {
        min-height: 52px;
      }
      .link {
        position: absolute;
        color: #fff;
        background: #0046C0;
        padding: 8px 20px;
        border-radius: 20px;
        top: 190px;
        left: 50%;
        transform: translate(-50%, 0);
        cursor: pointer;
      }
    }
    .item:first-child {
      margin-left: 0;
    }
    .item:last-child {
      margin-right: 0;
    }
  }
}
</style>
