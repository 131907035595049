<template>
  <div class="body-content">
    <banner v-if="bannerList&&bannerList.length>0" :homeBanner="bannerList"></banner>
    <div
      class="bg-rgba-w-1 h2"
      style="padding:20px;position: relative;top: -15px;min-height: 20px;"
    >
      <div class="hidden">{{ introduction.introduction }}</div>
      <div
        style="position: absolute;right: 20px;bottom: 20px;cursor: pointer;"
        @click="goMoreIndex"
      >更多>></div>
    </div>
    <div class="fast-nav">
      <div class="item" v-for="(item, index) in fastNav" :key="index">
        <div class="bg-rgba-w-1">
          <img :src="item.imgUrl" class="img" />
          <div class="text">
            <div class="title">{{item.title}}</div>
            <div class="number">{{item.num}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="top" style="display: none">
      <div class="h1 light-blue">揭榜挂帅</div>
      <div class="h2">
        <span>用全球大脑 解绵阳难题</span>
        <span class="more">更多>></span>
      </div>
      <div class="list">
        <div class="item" v-for="(item, index) in topList" :key="index">
          <img src="../static/images/home/bg.png" class="img" />
          <div class="content">
            <div class="desc">5G+8K超高清智能网关关键技术研究与应用</div>
            <span class="tag">电子信息</span>
            <div class="flex">
              <span>拟投资金（万元）</span>
              <span class="num">233</span>
            </div>
            <div class="btn">已发榜</div>
            <div class="small">四川九州电子科技股份有限公司</div>
          </div>
        </div>
      </div>
    </div>
    <pic-list
      title="大科学装置共享"
      :topLinks="shareInstrumentsLinks"
      :dataList="deviceMap"
      type="0"
      path="/sharedInstrument/equipment"
    ></pic-list>
    <!-- <section-title title="找活动" :showMore="true" @moreClick="moreClick('huodong')"></section-title> -->
    <activ-card-tab title="找活动" style="padding: 0;" :dataList="activityMap"></activ-card-tab>
    <flow-card-tab title="专利交易大厅"></flow-card-tab>
    <section-title title="环境试验" :showMore="true" @moreClick="moreClick('hjsy')"></section-title>
    <div class="shiyan-wrapper">
      <div class="item-wrapper" v-if="environmentList[0]">
        <img class="image" :src="environmentList[0].pictureUrl" />
        <div class="shadow"></div>
        <div class="h1 title center">{{ environmentList[0].title }}</div>
        <div class="button h3" @click="moreClick(environmentList[0].id)">查看详情</div>
      </div>
      <div class="divider-h"></div>
      <div class="item-wrapper" v-if="environmentList[1]">
        <img class="image" :src="environmentList[1].pictureUrl" />
        <div class="shadow"></div>
        <div class="h1 title center">{{ environmentList[1].title }}</div>
        <div class="button h3" @click="moreClick(environmentList[1].id)">查看详情</div>
      </div>
    </div>
    <section-title title="云上招引" :showMore="true" @moreClick="moreClick('yunshang')"></section-title>
    <div class="yunshang-menus-wrapper bg-rgba-w-1">
      <div
        class="yunshang-item"
        v-for="item in yunshangMenus"
        :key="item.code"
        @click="goYunshang(item)"
      >
        <img :src="item.image" class="image" />
        <div class="h4 menu-title">{{item.name}}</div>
      </div>
    </div>
    <div class="yunshangdaxue" @click="navTo('ysdx')">云上大学城</div>
  </div>
</template>

<script>
import Banner from '@/components/banner.vue';
import sectionTitle from '@/views/policyHall/components/section-title.vue'
import activCardTab from '@/views/technologyFinance/components/activCardTab.vue';
import flowCardTab from '@/views/intellectualProperty/components/flowCardTab.vue'
import PicList from '@/components/picList.vue';
export default {
  name: 'home-index',
  components: {
    Banner,
    sectionTitle,
    activCardTab,
    flowCardTab,
    PicList
  },
  data() {
    return {
      pageTopPosition: 0,

      bannerList: [],
      introduction: {},
      deviceMap: {},
      activityMap: {},
      fastNav: [
        {
          imgUrl: require('@/static/images/home/6-1.png'),
          title: '揭榜挂帅',
          num: '24个'
        },
        {
          imgUrl: require('@/static/images/home/5-4.png'),
          title: '科技需求',
          num: '215个'
        },
        {
          imgUrl: require('@/static/images/home/36.png'),
          title: '科技供给',
          num: '142名'
        },
        {
          imgUrl: require('@/static/images/home/1056.png'),
          title: '众创空间',
          num: '144个'
        },
        {
          imgUrl: require('@/static/images/home/6-1.png'),
          title: '交易金额',
          num: '75亿'
        },
        {
          imgUrl: require('@/static/images/home/5-4.png'),
          title: '交易数量',
          num: '1292项'
        }
      ],
      topList: [
        {}, {}, {}
      ],
      shareInstrumentsLinks: [
        {
          name: '离心试验',
          code: 1
        },
        {
          name: '振动试验',
          code: 2
        },
        {
          name: '风洞试验',
          code: 3
        },
        {
          name: '高空台试验',
          code: 4
        }, {
          name: '冲击试验',
          code: 5
        },
        {
          name: '星光激光装置试验',
          code: 6
        }
      ],
      yunshangMenus: [
        {
          'name': '招院引所',
          'code': 'zyys',
          'image': require('@/static/images/menu-zyys.png'),
          'path': 'solicitationCloud/page-zyys?menu_id=ruzhuyuansuo'
        },
        {
          'name': '招商引资',
          'code': 'zsyz',
          'image': require('@/static/images/menu-zsyz.png'),
          'path': 'solicitationCloud/page-zsyz?menu_id=ruzhuqiye'
        },
        {
          'name': '招才引智',
          'code': 'zcyz',
          'image': require('@/static/images/menu-zcyz.png'),
          'path': 'solicitationCloud/page-zcyz?menu_id=zhengcezhichi'
        },
        {
          'name': '院士小镇',
          'code': 'ysxz',
          'image': require('@/static/images/menu-ysxz.png'),
          'path': 'solicitationCloud/page-ysxz?menu_id=xiaozhenzhanshi'
        },
        {
          'name': '基金小镇',
          'code': 'jjxz',
          'image': require('@/static/images/menu-jjxz.png'),
          'path': 'solicitationCloud/page-jjxz?menu_id=xiaozhenzhanshi'
        },
      ],
      environmentList: []
    }
  },
  created() {
    this.index()
  },
  beforeRouteLeave(to, from, next) {
    this.pageTopPosition = document.getElementById('app').scrollTop
    next()
  },
  activated() {
    document.getElementById('app').scrollTop = this.pageTopPosition
  },
  methods: {
    //轮播图下的更多
    goMoreIndex() {
      // /api/index/pc/more
      this.$router.push('/about/index')
    },
    moreClick(type) {
      if (type == 'huodong') {
        //找活动
      } else if (type == 'zhuanli') {
        //专利交易大厅
      } else if (type == 'hjsy') {
        //环境试验
        this.$router.push('/digitalServices/page-hjsy')
      } else if (type == 'yunshang') {
        //云上招引
        this.$router.push('/solicitationCloud/index')
      } else {
        this.$router.push({ path: '/digitalServices/page-hjsy/articledetail', query: { id: type, 'detailType': 'ds-environment' } })
      }
    },
    goYunshang(item) {
      this.$router.push(item.path)
    },
    index() {
      this.$api.common.home().then((res) => {
        let data = res.data.data;
        this.bannerList = data.bannerList
        this.introduction = data.introduction
        this.deviceMap = data.deviceMap
        this.activityMap = data.activityMap
        this.environmentList = data.environmentList
        this.fastNav[0].num = (data.setting.leader || '0') + '个';
        this.fastNav[1].num = (data.setting.techRequirement || '0') + '个';
        this.fastNav[2].num = (data.setting.techSupply || '0') + '名';
        this.fastNav[3].num = (data.setting.makerSpace || '0') + '个';
        this.fastNav[4].num = (data.setting.tradeAmount || '0') + '亿';
        this.fastNav[5].num = (data.setting.tradeCount || '0') + '项';
      })
    },
    navTo() {
      window.location.href = "http://117.71.58.151:10000/home"
      // window.open('http://117.71.58.151:10000/home', 'yunshang')
    }
  }
}
</script>

<style scoped lang="less">
.banner {
  height: 584px;

  img {
    width: 100%;
    height: 100%;
  }
}
.fast-nav {
  display: flex;
  padding: 20px 0;
  margin-left: -10px;
  margin-right: -10px;
  flex-wrap: wrap;
  .item {
    width: 33.33333%;
    padding: 20px 10px;
    box-sizing: border-box;
    .bg-rgba-w-1 {
      border-radius: 8px;
      padding: 25px 50px;
      display: flex;
      align-items: center;
      height: 90px;
      display: flex;
      justify-content: center;
      .img {
        width: 70px;
        height: auto;
        margin-right: 40px;
      }
      .text {
        width: 180px;
        font-size: 20px;
        border-left: 3px solid #fff;
        // padding-left: 30px;
        height: 60px;
        text-align: center;
        .number {
          font-size: 30px;
          color: aqua;
        }
      }
    }
  }
}

.top {
  padding: 0 10px;
  .h2 {
    padding: 15px 0;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;
    .more {
      font-size: 16px;
      cursor: pointer;
      margin-right: 10px;
    }
  }
  .list {
    padding-top: 24px;
    .item {
      display: inline-block;
      width: 30%;
      margin-right: 5%;
      position: relative;
      .img {
        width: 100%;
        height: auto;
      }
      .content {
        position: absolute;
        top: 46%;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 0 16% 20px;
        .desc {
          font-size: 18px;
          color: aqua;
          line-height: 1.8;
          padding: 2.5% 0;
        }
        .tag {
          border: 1px solid rgba(255, 255, 255, 0.8);
          color: rgba(255, 255, 255, 0.8);
          font-size: 14px;
          padding: 6px 15px;
          display: inline-block;
          margin: 2.5% 0;
        }
        .flex {
          display: flex;
          justify-content: space-between;
          font-size: 18px;
          padding: 2.5% 0;
          .num {
            font-size: 24px;
            color: aqua;
          }
        }
        .btn {
          background-color: aqua;
          width: 80px;
          line-height: 32px;
          border-radius: 10px;
          margin: 2.5% auto;
          color: rgb(8, 32, 138);
          text-align: center;
        }
        .small {
          font-size: 12px;
          text-align: center;
          line-height: 2.4;
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 13%;
        }
      }
    }
    .item:last-child {
      margin: 0;
    }
  }
}
.shiyan-wrapper {
  display: flex;
  height: 380px;
  width: 100%;

  .item-wrapper {
    flex: 1;
    height: 100%;
    min-width: 100px;
    flex-shrink: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .shadow {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background: rgba(0, 0, 0, 0.6);
    }
    .center {
      width: 55%;
      text-align: center;
    }
    .image {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .title {
      text-align: center;
      margin: 20px;
      z-index: 2;
      font-size: 28px !important;
    }

    .button {
      width: 160px;
      height: 54px;
      text-align: center;
      line-height: 54px;
      cursor: pointer;
      z-index: 2;
      background-color: #f39800;
      border-radius: 17px;
      font-size: 20px;
    }
  }

  .divider-h {
    width: 20px;
    flex-shrink: 0;
  }
}
.yunshang-menus-wrapper {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 290px;
  .yunshang-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    .image {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
    .menu-title {
      text-align: center;
      margin-top: 10px;
    }
  }
}
.yunshangdaxue {
  height: 120px;
  margin-top: 90px;
  line-height: 120px;
  background-image: url("../static/images/yunshangdaxue.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  // font-weight: 700;
  font-size: 200%;
  cursor: pointer;
}
.hidden {
  padding-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 60px);
}
</style>
