<template>
  <div class="pic-list-block">
    <div class="title">
      <div class="left light-blue h1">{{title}}</div>
      <div class="more" @click="handleMoreClick">更多>></div>
    </div>
    <div class="content bg-rgba-w-1">
      <div class="titleCard">
        <label class="h1 liable">交易流程</label>
        <label class="h1 liable">\快速服务、流程清晰</label>
      </div>
      <div class="flowList">
        <div class="logo">
          <img src="../../../static/images/icon_business/1.png" height="70" width="70" />
          <label class="h1 liable">挑选专利</label>
        </div>
        <i class="el-icon-arrow-right"></i>
        <div class="logo">
          <img src="../../../static/images/icon_business/2.png" height="70" width="70" />
          <label class="h1 liable">签订合同</label>
        </div>
        <i class="el-icon-arrow-right"></i>
        <div class="logo">
          <img src="../../../static/images/icon_business/3.png" height="70" width="70" />
          <label class="h1 liable">办理转让</label>
        </div>
        <i class="el-icon-arrow-right"></i>
        <div class="logo">
          <img src="../../../static/images/icon_business/4.png" height="70" width="70" />
          <label class="h1 liable">提交资料</label>
        </div>
        <i class="el-icon-arrow-right"></i>
        <div class="logo">
          <img src="../../../static/images/icon_business/5.png" height="70" width="70" />
          <label class="h1 liable">交易完成</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'flowCardTab',
  props: {
    type: {
      type: String,
      default: null
    },
    showTitle: true,
    title: {
      type: String,
      default: ''
    },
    dataList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      current: 0,
      itemWidth: 0,
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    handleMoreClick() {
      this.$router.push("/intellectualProperty/patentList");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.pic-list-block {
  margin-top: 40px;
  .title {
    display: flex;
    position: relative;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 10px;
    margin-bottom: 20px;
    .more {
      position: absolute;
      bottom: 10px;
      right: 20px;
      cursor: pointer;
    }
    .middle {
      display: flex;
      padding: 15px 0 0 30px;
      .item {
        position: relative;
        padding-right: 30px;
      }
      .item::after {
        position: absolute;
        content: "";
        border-left: 2px solid #fff;
        transform: rotate(30deg);
        height: 24px;
        top: 3px;
        right: 13px;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    height: 210px;
    .titleCard {
      height: 30px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .flowList {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      .logo {
        //width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        .liable {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
