<template>
	<!-- 蓝色标题+下划线 -->
	<div class="section-wrapper">
		<div class="title">{{title}}</div>
		<div class="divider" v-if="hideDivider!==true"></div>
		<div class="more h3" v-if="showMore" @click="moreClick">更多>></div>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		props: ['title','showMore','hideDivider'],
		mounted() {

		},
		methods: {
			moreClick(){
				this.$emit('moreClick')
			},
		}
	}
</script>
<style scoped lang="less">
	.section-wrapper {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		margin: 10px 0;
		position: relative;
		.title {
			color: #00FFFF;
			margin: 16px 0;
			font-size: 28px;
		}

		.divider {
			height: 2px;
			background-color:rgba(255, 255, 255, 0.1);
		}
		.more{
			cursor: pointer;
			position: absolute;
			right: 0;
			bottom: 10px;
		}
	}
</style>
